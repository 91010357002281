/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../utils/path";
import { Col, Row } from "react-bootstrap";
import { FaBars, FaSignOutAlt, FaUser } from "react-icons/fa";
import { HiBell } from "react-icons/hi";
import { GenericBreadcrumb } from "../GenericActions";
import Notifications from "../../pages/Doctor/Notifications/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../app/features/auth/auth.slice";
import { getAllNotifications } from "../../app/features/notification/notification.slice";
import { getPatient } from "../../app/features/patient/patient.slice";

export function Header() {
  const [bellClicked, setBellClicked] = useState(false);

  document.body.style.backgroundColor = "#f5f5f5";

  let dispatch = useDispatch();
  let navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars

  const Logout = () => {
    dispatch(logout());
    // Notification("Logged Out!", "success");
  };

  const { user } = useSelector((state) => state.auth);
  const { getPatientData } = useSelector((state) => state.patient);
  const { allNotifications } = useSelector((state) => state.notification);

  useEffect(() => {
    const finalData = {
      patientId: user?.userId,
    };
    dispatch(getPatient(finalData));
  }, [dispatch, user?.userId]);

  useEffect(() => {
    const finalData = {
      isPagination: false,
    };
    dispatch(getAllNotifications(finalData));
  }, [dispatch]);

  useEffect(() => {
    if (bellClicked) {
      const finalData = {
        isPagination: false,
      };
      dispatch(getAllNotifications(finalData));
      setBellClicked(false); // Reset the state after fetching notifications
    }
  }, [bellClicked, dispatch]);

  function handlePreventClick(event) {
    event.preventDefault();
  }
  return (
    <>
      <Row className="header px-2">
        {/* Logo */}
        <Col lg={6} sm={6} xs={1} className="PageName pe-0">
          {/* Mobile Menu Toggle */}
          {/* <a href="javascript:void(0)" className="mobile_btn" id="mobile_btn"> */}
          <a
            href="https://"
            onClick={handlePreventClick}
            className="mobile_btn"
            id="mobile_btn"
          >
            <FaBars />
          </a>
          <span className="d-md-block d-sm-none breadcrumb_xs-none">
            <GenericBreadcrumb />
          </span>
        </Col>
        {/* /Logo */}

        {/* /Mobile Menu Toggle */}
        <Col
        lg={6}
        sm={6}
          xs={11}
          className="nav user-menu d-flex justify-content-end h-100"
        >
          <span className="notification-item nav-item dropdown has-arrow">
            <Dropdown className="user-dropdown h-100">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="user_dropdown h-100 custom-toggle px-0"
              >
                <span className="user-img mt-0">
                  <span
                    className={`d-flex align-items-center ${
                      allNotifications?.length > 0 ? "notify-dot" : ""
                    }`}
                    onClick={() => setBellClicked(true)}
                  >
                    <HiBell size={26} className="bell-icon" />
                  </span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="profile-dropmenu py-4">
                <div className="d-flex justify-content-between px-3">
                  <h4 className="fw-bold">Notifications</h4>
                  {/* {allNotifications.length > 0 && (
                    <div className="d-flex align-items-center cursor-pointer">
                      <p className="pe-1 mb-0" style={{ color: "#6045EB" }}>
                        Mark all as read
                      </p>
                      <BsCheckCircle style={{ color: "#6045EB" }} />
                    </div>
                  )} */}
                </div>
                <Dropdown.Item className="d-block px-2 hover-transparent">
                  {allNotifications.length > 0 ? (
                    <Notifications data={allNotifications} />
                  ) : (
                    <h6 className="text-black-50 d-flex align-items-center justify-content-center pt-5">
                      No Notification
                    </h6>
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>

          <span className="nav-item dropdown has-arrow">
            <Dropdown className="user-dropdown h-100">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="user_dropdown h-100 d-flex align-items-center"
              >
                <span className="d-flex align-items-center user-img mt-0">
                  <img
                    className="rounded-circle"
                    src={
                      getPatientData?.imageUrl
                        ? getPatientData?.imageUrl
                        : `https://ui-avatars.com/api/?name=${`${user?.name}`}&background=6045eb&color=fff`
                    }
                    width="31"
                    alt="Ryan Taylor"
                  />
                  <span className="">
                    <p className="head-user-text ms-2 mb-0">{user?.name}</p>
                    <p
                      style={{ textAlign: "left" }}
                      className="head-role-text mb-0 ms-2"
                    >
                      {user?.role === "Doctor"
                        ? "Doctor"
                        : user?.role === "Patient"
                        ? "Patient"
                        : user?.role === "Super Admin"
                        ? "Super Admin"
                        : user?.role === "Admin"
                        ? "Admin"
                        : user?.role === "Staff"
                        ? "Staff"
                        : ""}
                    </p>
                  </span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="profile-dropmenu">
                {(user?.role === "Doctor" || user?.role === "Patient") && (
                  <Dropdown.Item
                    className="d-block px-0"
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      user?.role === "Doctor"
                        ? navigate(PATH.PROFILE)
                        : user?.role === "Patient"
                        ? navigate(PATH.PATIENT_PROFILE)
                        : "";
                    }}
                  >
                    <span>
                      <FaUser className="me-2" />
                      My Profile
                    </span>
                  </Dropdown.Item>
                )}
                {(user?.role === "Admin" || user?.role === "Super Admin") && (
                  <Dropdown.Item
                    className="d-block px-0"
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      user?.role === "Admin"
                        ? navigate(PATH.ADMIN_SETTINGS)
                        : user?.role === "Super Admin"
                        ? navigate(PATH.SUPERADMIN_SETTINGS)
                        : "";
                    }}
                  >
                    <span>
                      <FaUser className="me-2" />
                      Settings
                    </span>
                  </Dropdown.Item>
                )}

                <Dropdown.Item
                  className="d-block px-0"
                  onClick={() => Logout()}
                >
                  <FaSignOutAlt className="me-2" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </Col>
        {/* <ReactTooltip /> */}
      </Row>
    </>
  );
}
