import api from "../../../utils/api";

const getAllAppointment = async (data) => {
    const response = await api.post(`/appointment/getAll`, data);
    return response.data;
  };

  const getAllAppointedPatients = async (data) => {
    const response = await api.post(`/appointment/getAllAppointedPatients`, data);
    return response.data;
  };

  const changeDoctorAppointment = async (data) => {
    const response = await api.post(`/appointment/changeDoctorAppointment`, data);
    return response.data;
  };

  const createAppointment = async (data) => {
    const response = await api.post(`/appointment/create`, data);
    return response.data;
  };

  const getPatientAppointmentDetail = async (data) => {
    const response = await api.get(`/appointment/GetPatientDetail`, {
        params: data,
      });
    return response.data;
  };

  const getDoctorAppointmentDetail = async (data) => {
    const response = await api.get(`/appointment/GetDoctorDetail`, {
        params: data,
      });
    return response.data;
  };

  const getPatientAppointmentNotesDetail = async (data) => {
    const response = await api.get(`/appointment/getPatientApppointmentDetail`, {
        params: data,
      });
    return response.data;
  };
  const changeAppointmentStatus = async (appointmentStatus) => {
    const response = await api.put(
      `/appointment/updateStatus`,
      appointmentStatus
    );
    return response.data;
  };

  const joinAppointment = async (data) => {
    const response = await api.post(`/appointment/joinAppointment`, data);
    return response.data;
  };
  const rescheduleAppointment = async (data) => {
    const response = await api.post(`/appointment/rescheduleAppointment`, data);
    return response.data;
  };
  const appointment = {
    getAllAppointment,
    getAllAppointedPatients,
    createAppointment,
    getPatientAppointmentDetail,
    getDoctorAppointmentDetail,
    getPatientAppointmentNotesDetail,
    changeDoctorAppointment,
    changeAppointmentStatus,
    joinAppointment,
    rescheduleAppointment
  };
  
  export default appointment;