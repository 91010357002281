import api from "../../../utils/api";

// register as a patient
const createAccount = async (patientAccount) => {
  const response = await api.post(`/account/register`, patientAccount);
  return response.data;
};

// login
const logIn = async (userData) => {
  const response = await api.post(`/account/login`, userData);
  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem("ogow_health_app");
  localStorage.removeItem("slotDescription");
};

// confirm otp
const confirmOtp = async (otpCode) => {
  const response = await api.post(`/account/confirmOtp`, otpCode);
  return response.data;
};

// resend otp
const resendOtp = async (resendOtpCode) => {
  const response = await api.post(`/account/resendOtp`, resendOtpCode);
  return response.data;
};

// forget password - send otp to email
const sendOtpToEmail = async (forgetPasswordSendOtp) => {
  const response = await api.put(
    `/account/sendOTPToEmail`,
    forgetPasswordSendOtp
  );
  return response.data;
};
// forget password - confirm otp by email
const confirmOtpByEmail = async (forgetPasswordConfirmOtp) => {
  const response = await api.put(
    `/account/confirmOTPByEmail`,
    forgetPasswordConfirmOtp
  );
  return response.data;
};
// forget password - confirm
const forgetPasswordReset = async (forgetPasswordConfirm) => {
  const response = await api.put(
    `/account/forgetPasswordReset`,
    forgetPasswordConfirm
  );
  return response.data;
};
const changePassword = async (changePasswordProfile) => {
  const response = await api.put(
    `/account/changePassword`,
    changePasswordProfile
  );
  return response.data;
};

const authService = {
  createAccount,
  logIn,
  logout,
  confirmOtp,
  resendOtp,
  sendOtpToEmail,
  confirmOtpByEmail,
  forgetPasswordReset,
  changePassword
};

export default authService;
