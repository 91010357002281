import React from "react";
//import { Modal, Button, Popover } from "react-bootstrap";
import { BsFillCalendarFill } from "react-icons/bs";

import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment/moment";
import { eclipseColoeFunc, iconcolorFunc } from "../../../components/GenericActions";

const DateAndDetails = ({ iconcolor, color, day, status, description }) => {
  return (
    <div>
      <Dropdown.Item>
        <div className="d-flex w-100">
          <div
            style={{
              backgroundColor: color,
            }}
            className="notification-custom-icons d-flex justify-content-center align-items-center"
          >
            <BsFillCalendarFill size={24} style={{ color: iconcolor }} />
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between w-100">
              <p className="m-0 px-2 fw-bold">{status}</p>
              <p className="m-0 px-2" style={{ color: "#999999" }}>
                {day}
              </p>
            </div>
            <p className="m-0 px-2 text-wrap" style={{ color: "#999999" }}>
              {description}
            </p>
          </div>
        </div>
      </Dropdown.Item>
      <hr />
    </div>
  );
};
const DateAndDetailsList = ({ data }) => {
  return (
    <>
      {data.map(({ notificationTypeId, createdDate, notificationTitle, description }) => (
        <DateAndDetails
          iconcolor={iconcolorFunc(notificationTypeId)}
          color={eclipseColoeFunc(notificationTypeId)}
          day={
            moment(createdDate).format("DD-MM-YYYY") ===
            moment(new Date()).format("DD-MM-YYYY")
              ? "Today"
              : moment(createdDate).format("DD/MM/YY")
          }
          status={notificationTitle}
          description={description}
        />
      ))}
    </>
  );
};

export default function Notifications(props) {
  return (
    <>{props.data.length > 0 && <DateAndDetailsList data={props.data} />}</>
  );
}
