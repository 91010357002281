import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import patientHistory from "./patientHistort.service";
import { Notification } from "../../../components/GenericActions";

const initialState = {
  patientHistoryData: null,
  createPatientHistoryData: null,
  updatePatientHistoryData: null,
  deletePatientHistortData: null,
  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getPatientHistory = createAsyncThunk(
  "patientHistory/getPatientHistory",
  async (finalData, thunkAPI) => {
    try {
      return await patientHistory.getPatientHistory(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createPatientHistory = createAsyncThunk(
  "patientHistory/createPatientHistory",
  async ({ finalData, moveToNextMedicalHistory }, thunkAPI) => {
    try {
      const response = await patientHistory.createPatientHistory(finalData);
      if (response.succeeded === true) {
        if (moveToNextMedicalHistory) {
          moveToNextMedicalHistory(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePatientHistory = createAsyncThunk(
  "patientHistory/updatePatientHistory",
  async ({ finalData, moveToNextMedicalHistory }, thunkAPI) => {
    try {
      const response = await patientHistory.updatePatientHistory(finalData);
      if (response.succeeded === true) {
        if (moveToNextMedicalHistory) {
          moveToNextMedicalHistory(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePatientHistory = createAsyncThunk(
  "patientHistory/deletePatientHistory",
  async ({ finalData, moveToNextDeleteMedicalHistory }, thunkAPI) => {
    try {
      const response = await patientHistory.deletePatientHistory(finalData);
      if (response.succeeded === true) {
        if (moveToNextDeleteMedicalHistory) {
          moveToNextDeleteMedicalHistory(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const patientHistorySlice = createSlice({
  name: "patientHistory",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPatientHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientHistoryData = action.payload.data;
      })
      .addCase(getPatientHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(createPatientHistory.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createPatientHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createPatientHistoryData = action.payload;
      })
      .addCase(createPatientHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(updatePatientHistory.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updatePatientHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updatePatientHistoryData = action.payload;
      })
      .addCase(updatePatientHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(deletePatientHistory.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(deletePatientHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePatientHistortData = action.payload;
      })
      .addCase(deletePatientHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = patientHistorySlice.actions;

export default patientHistorySlice.reducer;
