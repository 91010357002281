import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import appointment from "./appointment.service";
import { Notification } from "../../../components/GenericActions";

const initialState = {
  allAppointment: [],
  createPatientAppointment: null,
  getAllAptPatients: [],
  updateStatus: null,
  appointmentJoinData: null,
  rescheduleAppointmentData: null,
  isLoggedIn: false,
  changeAppointment: null,
  allAppointedPatient: null,
  doctorAppointedDetail: null,
  patientAppointedDetail: null,
  patientAppointmentNotes: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

// export const getAllAppointedPatients = createAsyncThunk(
//   "appointment/getAllAppointedPatients",
//   async ({ finalData }, thunkAPI) => {
//     try {
//       const response = await appointment.getAllAppointedPatients(finalData);
//       if (response.succeeded === true) {
//         // Notification(response.message, response.succeeded);
//         return response;
//       }
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       Notification(message, false);
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const getAllAppointment = createAsyncThunk(
  "appointment/getAllAppointment",
  async (finalData, thunkAPI) => {
    try {
      return await appointment.getAllAppointment(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

  export const getAllAppointedPatients = createAsyncThunk(
    "appointment/getAllAppointedPatients",
    async (finalData, thunkAPI) => {
      try {
        return await appointment.getAllAppointedPatients(finalData);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        Notification(message, false);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


export const createAppointment = createAsyncThunk(
  "appointment/createAppointment",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await appointment.createAppointment(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const changeDoctorAppointment = createAsyncThunk(
  "appointment/changeDoctorAppointment",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await appointment.changeDoctorAppointment(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
  export const getPatientAppointmentDetail = createAsyncThunk(
    "appointment/getPatientAppointmentDetail",
    async (finalData, thunkAPI) => {
      try {
        return await appointment.getPatientAppointmentDetail(finalData);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        Notification(message, false);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getDoctorAppointmentDetail = createAsyncThunk(
    "appointment/getDoctorAppointmentDetail",
    async (finalData, thunkAPI) => {
      try {
        return await appointment.getDoctorAppointmentDetail(finalData);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        Notification(message, false);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getPatientAppointmentNotesDetail = createAsyncThunk(
    "appointment/getPatientAppointmentNotesDetail",
    async (finalData, thunkAPI) => {
      try {
        return await appointment.getPatientAppointmentNotesDetail(finalData);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        Notification(message, false);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const changeAppointmentStatus = createAsyncThunk(
    "appointment/changeAppointmentStatus",
    async ({finalData, moveToNext}, thunkAPI) => {
      try {
        const response = await appointment.changeAppointmentStatus(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        Notification(message, false);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const joinAppointment = createAsyncThunk(
    "appointment/joinAppointment",
    async (finalData, thunkAPI) => {
      try {
        return await appointment.joinAppointment(finalData);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        Notification(message, false);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const rescheduleAppointment = createAsyncThunk(
    "appointment/rescheduleAppointment",
    async ({finalData, moveToNext}, thunkAPI) => {
      try {
        const response = await appointment.rescheduleAppointment(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        Notification(message, false);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  const appointmentSlice = createSlice({
    name: "appointment",
    initialState,
    reducers: {
      clearData: (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
        state.statusMessage = "";
      },
    },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAppointment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allAppointment = action.payload.data;
      })
      .addCase(getAllAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(createAppointment.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createPatientAppointment = action.payload;
      })
      .addCase(createAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllAppointedPatients.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAllAppointedPatients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allAppointedPatient = action.payload.data;
      })
      .addCase(getAllAppointedPatients.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getPatientAppointmentDetail.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getPatientAppointmentDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientAppointedDetail = action.payload.data;
      })
      .addCase(getPatientAppointmentDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getDoctorAppointmentDetail.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getDoctorAppointmentDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.doctorAppointedDetail = action.payload.data;
      })
      .addCase(getDoctorAppointmentDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getPatientAppointmentNotesDetail.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getPatientAppointmentNotesDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientAppointmentNotes = action.payload.data;
      })
      .addCase(getPatientAppointmentNotesDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(changeDoctorAppointment.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(changeDoctorAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.changeAppointment = action.payload;
      })
      .addCase(changeDoctorAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(changeAppointmentStatus.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(changeAppointmentStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateStatus = action.payload;
      })
      .addCase(changeAppointmentStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(joinAppointment.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(joinAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.appointmentJoinData = action.payload;
      })
      .addCase(joinAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(rescheduleAppointment.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(rescheduleAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rescheduleAppointmentData = action.payload;
      })
      .addCase(rescheduleAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
    },
  });
export const { clearData } = appointmentSlice.actions;

export default appointmentSlice.reducer;
