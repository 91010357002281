import api from "../../../utils/api";

// create user role
const createUser = async (createUserRole) => {
  const response = await api.post(`/user/create`, createUserRole);
  return response.data;
};
// get all users
const getAllUser = async (getAllUserRole) => {
  const response = await api.post(`/user/getAll`, getAllUserRole);
  return response.data;
};
// delete users
const deleteUser = async (userId) => {
  const response = await api.delete(`/user/delete?userId=${userId?.userId}`);
  return response.data;
};

const getUser = async (userId) => {
  const response = await api.get(`/user/get?userId=${userId?.userId}`);
  return response.data;
};

const updateUser = async (req) => {
  const { data } = await api.put(`/user/Update`, req);
  if (data.data) {
    let updatedData = JSON.parse(localStorage.getItem("ogow_health_app"));
    updatedData = {
      ...updatedData,
      name: `${data?.data?.firstName} ${data?.data?.lastName}`,
      imageUrl: data?.data?.imageUrl,
    };
    localStorage.setItem("ogow_health_app", JSON.stringify(updatedData));
  }
  return data;
};

const userRoleService = {
  createUser,
  getAllUser,
  getUser,
  updateUser,
  deleteUser,
};

export default userRoleService;
