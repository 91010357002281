import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./auth.service";
import { Notification } from "../../../components/GenericActions";
const user = JSON.parse(localStorage.getItem("ogow_health_app"));

const initialState = {
  createAccounts: null,
  createAccountSuccess: null,
  user: user || null,
  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isOtp: null,
  isresendOtp: null,
  forgetPasswordSendOtp: null,
  forgetPasswordConfirmOtp: null,
  isConfirmForgetPassword: null,
  changePasswordProfile: null,
  statusMessage: "",
};

export const createAccount = createAsyncThunk(
  "signUp/createAccount",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await authService.createAccount(finalData);

      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext();
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const logIn = createAsyncThunk(
  "auth/login",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await authService.logIn(finalData);

      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

export const confirmOtp = createAsyncThunk(
  "auth/confirmOtp",
  async ({ confirmOtpData, moveToNext }, thunkAPI) => {
    try {
      const response = await authService.confirmOtp(confirmOtpData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const resendOtp = createAsyncThunk(
  "auth/resendOtp",
  async ({ resendOtpData, moveToNext }, thunkAPI) => {
    try {
      const response = await authService.resendOtp(resendOtpData);

      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext();
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const sendOtpToEmail = createAsyncThunk(
  "auth/sendOtpToEmail",
  async ({ sendOtpToEmailData, moveToNext }, thunkAPI) => {
    try {
      const response = await authService.sendOtpToEmail(sendOtpToEmailData);

      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const confirmOtpByEmail = createAsyncThunk(
  "auth/confirmOtpByEmail",
  async ({ confirmOtpByEmailData, moveToState2 }, thunkAPI) => {
    try {
      const response = await authService.confirmOtpByEmail(
        confirmOtpByEmailData
      );

      if (response.succeeded === true) {
        if (moveToState2) {
          moveToState2(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const forgetPasswordReset = createAsyncThunk(
  "auth/forgetPasswordConfirm",
  async ({ finalData, moveToState3 }, thunkAPI) => {
    try {
      const response = await authService.forgetPasswordReset(finalData);

      if (response.succeeded === true) {
        if (moveToState3) {
          moveToState3(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ finalData, moveToState3 }, thunkAPI) => {
    try {
      const response = await authService.changePassword(finalData);

      if (response.succeeded === true) {
        if (moveToState3) {
          moveToState3(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
    updatedProfile: (state) => {
      const updatedUser = JSON.parse(localStorage.getItem("ogow_health_app"));
      state.user = updatedUser;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createAccounts = action.payload;
      })
      .addCase(createAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(logIn.pending, (state) => {
        state.isLoading = true;
        state.message = "";
        state.user = null;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        // state.user = action.payload;
      })
      .addCase(logIn.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      })
      .addCase(confirmOtp.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(confirmOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.isOtp = action.payload;
        state.user = action.payload?.data;
      })
      .addCase(confirmOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(resendOtp.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(resendOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.resendOtp = action.payload;
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(sendOtpToEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendOtpToEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.forgetPasswordSendOtp = action.payload;
      })
      .addCase(sendOtpToEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(confirmOtpByEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(confirmOtpByEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.forgetPasswordConfirmOtp = action.payload;
      })
      .addCase(confirmOtpByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(forgetPasswordReset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgetPasswordReset.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.forgetPasswordReset = action.payload;
      })
      .addCase(forgetPasswordReset.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.changePasswordProfile = action.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData,  updatedProfile} = authSlice.actions;

export default authSlice.reducer;
