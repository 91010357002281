import api from "../../../utils/api";

const getNotification = async (data) => {
    const response = await api.get(`/Notifications/getNotification`, {
        params: data,
      });
    return response.data;
  };

  const getAllNotifications = async (data) => {
    const response = await api.post(`/Notifications/getAllNotifications`, data);
    return response.data;
  };

  const createNotifcations = async (data) => {
    const response = await api.post(`/Notifications/createNotifcations`, data);
    return response.data;
  };

  const updateNotification = async (data) => {
    const response = await api.put(`/Notifications/updateNotifications`, data);
    return response.data;
  };

  const deleteNotification = async (data) => {
    const response = await api.delete("/Notifications/delete", {
      params: data,
    } );
    return response.data;
  };

  const notification = {
    getNotification,
    getAllNotifications,
    createNotifcations,
    updateNotification,
    deleteNotification,
  };
  
  export default notification;