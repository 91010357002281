 const ICON_TAG_COLORS = [
  {
    notificationTypeId: 301,
    colorClass: "#44BC19",
  },
  {
    notificationTypeId: 302,
    colorClass: "#2269F2",
  },
  {
    notificationTypeId: 303,
    colorClass: "#6045EB",
  },
  {
    notificationTypeId: 304,
    colorClass: "#F26522",
  },
  {
    notificationTypeId: 305,
    colorClass: "#FF1616",
  },
];

const ECLIPSE_TAG_COLORS = [
    {
      notificationTypeId: 301,
      colorClass: "#CEFDBE",
    },
    {
      notificationTypeId: 302,
      colorClass: "#C4D8FF",
    },
    {
      notificationTypeId: 303,
      colorClass: "#DBD3FF",
    },
    {
      notificationTypeId: 304,
      colorClass: "#f7b799",
    },
    {
      notificationTypeId: 305,
      colorClass: "#FFBBBB",
    },
  ];
  
  export {
    ICON_TAG_COLORS,
    ECLIPSE_TAG_COLORS,
  };