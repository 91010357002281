import { toast } from "react-toastify";
import moment from "moment";
import { Breadcrumb } from "react-bootstrap";
import { ECLIPSE_TAG_COLORS, ICON_TAG_COLORS } from "../../utils/color";

function Notification(data, condition) {
  if (condition === "success" || condition === true) {
    toast.success(data, {
      position: "top-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      // className: "print-data",
      progress: undefined,
      theme: "light",
      //   theme: "colored",
    });
  } else {
    toast.error(data, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function changeDateFormat(date) {
  var obj = moment(date).format("YYYY-MM-DD");
  obj = obj + "T00:00:00.000Z";
  return obj;
}
export function GenericBreadcrumb() {
  const url = window.location.pathname.split("/");
  // console.log("params:::", window.location.pathname.split('/'));
  return (
    <Breadcrumb className="header-breadcrumb fs-4 mb-0 ms-4 ms-lg-0 ms-md-4 ms-sm-0 ms-xl-0">
      <Breadcrumb.Item className="ms-3 breadcrum_Project">
        {url[2]?.replaceAll("-", " ")}
      </Breadcrumb.Item>
      {/* {url[2] && <Breadcrumb.Item>{url[2]?.replaceAll("-"," ")}</Breadcrumb.Item>} */}
    </Breadcrumb>
  );
}
export { Notification, changeDateFormat };

export default function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const getCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

function convertTo24Hour(time12h) {
  const [time, modifier] = time12h?.split(" ");

  let [hours, minutes] = time?.split(":");

  if (modifier === "PM" && hours !== "12") {
    hours = String(Number(hours) + 12);
  }

  if (modifier === "AM" && hours === "12") {
    hours = "00";
  }

  return `${hours}:${minutes}`;
}
const convertTimeIntoUTC = (time, withDate = null) => {
  const date = withDate;
  const utcTime = new Date(`${date} ${time}`).toISOString();
  return utcTime;
};

const iconcolorFunc = (tag) => {
  const tagColor = ICON_TAG_COLORS.find(
    (item) => item.notificationTypeId === tag
  );
  return tagColor?.colorClass;
};
const eclipseColoeFunc = (tag) => {
  const tagColor = ECLIPSE_TAG_COLORS.find(
    (item) => item.notificationTypeId === tag
  );
  return tagColor?.colorClass;
};
export {
  tConvert,
  getCurrentDate,
  getCurrentTime,
  convertTimeIntoUTC,
  convertTo24Hour,
  iconcolorFunc,
  eclipseColoeFunc,
};
