import api from "../../../utils/api";

const getTotalAppointments = async (data) => {
  const response = await api.get(`/PatientDashBoard/GetTotalAppointments`, {
    params: data,
  });
  return response.data;
};


const patientDashboard = {
  getTotalAppointments,
};

export default patientDashboard;
