/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation } from "react-router-dom";
import "../../assets/js/jquerry.slimscroll";
import { RiLayoutGridFill } from "react-icons/ri";
import { HiUserAdd } from "react-icons/hi";
import { SiGoogleforms } from "react-icons/si";
import IMAGES from "../../assets/images";
import { PATH } from "../../utils/path.js";
import {
  BsCalendarFill,
  BsClockFill,
  BsFillCreditCardFill,
} from "react-icons/bs";
import { AiOutlineWechat } from "react-icons/ai";
import {
  FaFilePrescription,
  FaLayerGroup,
  FaUserMd,
} from "react-icons/fa";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const { user } = useSelector((state) => state.auth);

  let location = useLocation();
  let sidebarContent;
  if (user?.roleId === 2) {
    sidebarContent = (
      <>
        <li
          className={
            location.pathname === PATH.DOCTOR_DASHBOARD ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: PATH.DOCTOR_DASHBOARD,
            }}
          >
            <RiLayoutGridFill />
            <span>Dashboard</span>
          </Link>
        </li>
        <li className={location.pathname === PATH.APPOINTMENT ? "active" : ""}>
          <Link
            to={{
              pathname: PATH.APPOINTMENT,
            }}
          >
            <BsCalendarFill size={19} />
            <span>Appointment</span>
          </Link>
        </li>

        <li className={location.pathname === PATH.PATIENTS ? "active" : ""}>
          <Link
            to={{
              pathname: PATH.PATIENTS,
            }}
          >
            <FaUserMd />
            <span>Patients</span>
          </Link>
        </li>
        <li className={location.pathname === PATH.STAFF ? "active" : ""}>
          <Link
            to={{
              pathname: PATH.STAFF,
            }}
          >
            <HiUserAdd />
            <span>Staff</span>
          </Link>
        </li>
        <li className={location.pathname === PATH.CHAT ? "active" : ""}>
          <Link
            to={{
              pathname: PATH.CHAT,
            }}
          >
            <AiOutlineWechat />
            <span>Chat</span>
          </Link>
        </li>
        <li
          className={location.pathname === PATH.DOCTOR_PRESCRIPTION_DASHBOARD ? "active" : ""}
        >
          <Link
            to={{
              pathname: PATH.DOCTOR_PRESCRIPTION_DASHBOARD,
            }}
          >
            <SiGoogleforms />
            <span className="text-wrap">Online Prescription Forms</span>
          </Link>
        </li>
      </>
    );
  } else if (user?.roleId === 3) {
    sidebarContent = (
      <>
        <li
          className={
            location.pathname === PATH.PATIENT_DASHBOARD ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: PATH.PATIENT_DASHBOARD,
            }}
          >
            <RiLayoutGridFill />
            <span>Dashboard</span>
          </Link>
        </li>
        <li
          className={
            location.pathname === PATH.PATIENT_APPOINTMENTDETAILS
              ? "active"
              : ""
          }
        >
          <Link
            to={{
              pathname: PATH.PATIENT_APPOINTMENTDETAILS,
            }}
          >
            <BsCalendarFill size={19} />
            <span>Appointment</span>
          </Link>
        </li>
        <li
          className={
            location.pathname === PATH.PATIENT_PAYMENTDETAILS ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: PATH.PATIENT_PAYMENTDETAILS,
            }}
          >
            <BsFillCreditCardFill />
            <span>Payment Details</span>
          </Link>
        </li>
        <li
          className={
            location.pathname === PATH.PATIENT_PRESCRIPTION ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: PATH.PATIENT_PRESCRIPTION,
            }}
          >
            <FaFilePrescription />
            <span>Prescriptions</span>
          </Link>
        </li>
        <li
          className={location.pathname === PATH.DOCTOR_DETAILS ? "active" : ""}
        >
          <Link
            to={{
              pathname: PATH.DOCTOR_DETAILS,
            }}
          >
            <FaLayerGroup />
            <span>Slots</span>
          </Link>
        </li>
        <li
          className={location.pathname === PATH.PATIENT_PRESCRIPTION_FORMS ? "active" : ""}
        >
          <Link
            to={{
              pathname: PATH.PATIENT_PRESCRIPTION_FORMS,
            }}
          >
            <SiGoogleforms />
            <span className="text-wrap">Online Prescription Forms</span>
          </Link>
        </li>
      </>
    );
  } else if (user?.roleId === 1) {
    sidebarContent = (
      <>
        <li
          className={
            location.pathname === PATH.SUPERADMIN_DASHBOARD ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: PATH.SUPERADMIN_DASHBOARD,
            }}
          >
            <RiLayoutGridFill />
            <span>Dashboard</span>
          </Link>
        </li>
        <li
          className={
            location.pathname === PATH.SUPERADMIN_USERMANAGEMENT ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: PATH.SUPERADMIN_USERMANAGEMENT,
            }}
          >
            <BsCalendarFill size={19} />
            <span>User Management</span>
          </Link>
        </li>
        <li
          className={
            location.pathname === PATH.SUPERADMIN_PAYMENTDETAILS ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: PATH.SUPERADMIN_PAYMENTDETAILS,
            }}
          >
            <BsFillCreditCardFill />
            <span>Payment Details</span>
          </Link>
        </li>
        <li className={location.pathname === PATH.SLOTS ? "active" : ""}>
          <Link
            to={{
              pathname: PATH.SLOTS,
            }}
          >
            <BsClockFill />
            <span>Slots</span>
          </Link>
        </li>
        <li
          className={
            location.pathname === PATH.SUPERADMIN_DOCTORS ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: PATH.SUPERADMIN_DOCTORS,
            }}
          >
            <HiUserAdd size={24} />
            <span>Doctors</span>
          </Link>
        </li>
        <li
          className={location.pathname === PATH.SUPERADMIN_FORMS ? "active" : ""}
        >
          <Link
            to={{
              pathname: PATH.SUPERADMIN_FORMS,
            }}
          >
            <SiGoogleforms size={20} />
            <span>Forms</span>
          </Link>
        </li>
      </>
    );
  } else if (user?.roleId === 4) {
    sidebarContent = (
      <>
        <li
          className={location.pathname === PATH.ADMIN_DASHBOARD ? "active" : ""}
        >
          <Link
            to={{
              pathname: PATH.ADMIN_DASHBOARD,
            }}
          >
            <RiLayoutGridFill />
            <span>Dashboard</span>
          </Link>
        </li>
        <li
          className={
            location.pathname === PATH.ADMIN_USERMANAGEMENT ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: PATH.ADMIN_USERMANAGEMENT,
            }}
          >
            <BsCalendarFill size={19} />
            <span>User Management</span>
          </Link>
        </li>
        <li
          className={
            location.pathname === PATH.ADMIN_PAYMENTDETAILS ? "active" : ""
          }
        >
          <Link
            to={{
              pathname: PATH.ADMIN_PAYMENTDETAILS,
            }}
          >
            <BsFillCreditCardFill />
            <span>Payment Details</span>
          </Link>
        </li>
        <li className={location.pathname === PATH.SLOTS ? "active" : ""}>
          <Link
            to={{
              pathname: PATH.SLOTS,
            }}
          >
            <BsClockFill />
            <span>Slots</span>
          </Link>
        </li>
        <li
          className={location.pathname === PATH.ADMIN_DOCTORS ? "active" : ""}
        >
          <Link
            to={{
              pathname: PATH.ADMIN_DOCTORS,
            }}
          >
            <HiUserAdd size={24} />
            <span>Doctors</span>
          </Link>
        </li>
        <li
          className={location.pathname === PATH.ADMIN_FORMS ? "active" : ""}
        >
          <Link
            to={{
              pathname: PATH.ADMIN_FORMS,
            }}
          >
            <SiGoogleforms size={20} />
            <span>Forms</span>
          </Link>
        </li>
      </>
    );
  } else if (user?.roleId === 5) {
    sidebarContent = (
      <li
        className={location.pathname === PATH.STAFF_SCREEN_CHAT ? "active" : ""}
      >
        <Link
          to={{
            pathname: PATH.CHAT,
          }}
        >
          <AiOutlineWechat />
          <span>Chat</span>
        </Link>
      </li>
    );
  }
  return (
    <div className="sidebar px-3" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <div className="header-left my-4 d-flex justify-content-center pageName">
            <Link
              to={PATH.DASHBOARD}
              className="logo mr-0 header-logo-image text-decoration-none"
            >
              <img alt="sidebar logo" src={IMAGES.SIDEBAR_LOGO}/>
            </Link>
            {/* <a href="javascript:void(0)" id="toggle_btn">
              <FaBars className="toggleset" />
            </a> */}
          </div>

          <ul>{sidebarContent}</ul>
        </div>
      </div>
    </div>
  );
}
