import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import onlinePrescriptionFormServices from "./OnlinePrescriptionForm.service";

const initialState = {
  adminOnlinePrescripionForm: null,
  getAllAdminOnlinePrescripionForm: [],

  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createAdminPresForm = createAsyncThunk(
  "admin/createPresForm",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await onlinePrescriptionFormServices.createAdminPresForm(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAdminPresForm = createAsyncThunk(
  "admin/getAdminPresForm",
  async (finalData, thunkAPI) => {
    try {
      return await onlinePrescriptionFormServices.getAdminPresForm(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllAdminPresForm = createAsyncThunk(
  "admin/getAllPresForm",
  async (finalData, thunkAPI) => {
    try {
      const response = await onlinePrescriptionFormServices.getAllAdminPresForm(
        finalData
      );
      if (response.succeeded === true) {
        return response;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const adminOnlinePrescripionSlice = createSlice({
  name: "adminOnlinePrescripion",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAdminPresForm.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createAdminPresForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminOnlinePrescripionForm = action.payload;
      })
      .addCase(createAdminPresForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllAdminPresForm.pending, (state) => {
        state.isLoading = true;
        state.getAllAdminOnlinePrescripionForm = [];
      })
      .addCase(getAllAdminPresForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllAdminOnlinePrescripionForm = action.payload;
      })
      .addCase(getAllAdminPresForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAdminPresForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminPresForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminOnlinePrescripionForm = action.payload;
      })
      .addCase(getAdminPresForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = adminOnlinePrescripionSlice.actions;

export default adminOnlinePrescripionSlice.reducer;
