import api from "../../../utils/api";

const getPatient = async (data) => {
  const response = await api.get(`/patient/get`, {
    params: data,
  });
  return response.data;
};

const getAllPatients = async (data) => {
  const response = await api.get(`/patient/getAll`, {
    params: data,
  });
  return response.data;
};

const updatePatient = async (req) => {
  const { data } = await api.put(`/patient/update`, req);
  if (data.data) {
    let updatedData = JSON.parse(localStorage.getItem("ogow_health_app"));
    updatedData = {
      ...updatedData,
      name: `${data?.data?.firstName} ${data?.data?.lastName}`,
      imageUrl: data?.data?.imageUrl,
    };
    localStorage.setItem("ogow_health_app", JSON.stringify(updatedData));
  }
  return data;
};

const deletePatient = async (data) => {
  const response = await api.delete("/api/patient/delete", { data });
  return response.data;
};

const deletePatientAccount = async (data) => {
  const response = await api.post("/account/delete", data);
  return response;
};
const patient = {
  getPatient,
  getAllPatients,
  updatePatient,
  deletePatient,
  deletePatientAccount
};

export default patient;
