// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { ParentComponentWithSideBar } from "../components/Layout/ParentComponentWithSideBar";
import { PATH } from "../utils/path";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";


export const socket = io("https://socketapp.contestantcenter.com/chat");

function withOrWithoutSIdebar() {
  switch (window.location.pathname) {
    case PATH.TELE_CONSULTATION:
      return true;
    case PATH.APPOINTMENT_NOTES:
      return true;
      case PATH.PATIENT_TELE_CONSULTATION:
      return true;
    default:
      return false;
  }
}
function completeProfileLayout() {
  switch (window.location.pathname) {
    case PATH.COMPLETE_PROFILE:
      return true;
    case PATH.PERSONAL_INFORMATION:
      return true;
    case PATH.MEDICAL_HISTORY:
      return true;
    case PATH.PHARMACY:
      return true;
    default:
      return false;
  }
}
function ProtectedRoute({ path, element }) {
  let withSidebar = withOrWithoutSIdebar();
  let withoutSidebar = completeProfileLayout();
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(PATH.SIGNIN);
    }
  }, [user, navigate]);

  return !withSidebar && !withoutSidebar ? (
    <ParentComponentWithSideBar>{element}</ParentComponentWithSideBar>
  ) : (
    element
  );
}

ProtectedRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default ProtectedRoute;
