import api from "../../../utils/api";

// create patient prescription
const createPatientPrescription = async (saveData) => {
  const response = await api.post(`/Medicine/create`, saveData);
  return response.data;
};

// get all patient prescription
const getAllPrescription = async (saveData) => {
  const response = await api.post(`/prescription/getall`, saveData);
  return response.data;
};

// delete patient prescription
const deletePatientPrescription = async (data) => {
  const response = await api.delete(`/Medicine/delete`, {
    params: data,
  });
  return response.data;
};

const getPescription = async (data) => {
  const response = await api.get(`/prescription/get`, {
    params: data,
  });
  return response.data;
};

const updatePatientPrescription = async (data) => {
  const response = await api.put(`/Medicine/update`, data);
  return response.data;
};
const showPatientPrescription = async (data) => {
  const response = await api.post(`/prescription/showPrescription`, data);
  return response.data;
};
const getMedicationList = async (data) => {
  const response = await api.get(`/medication/getList`, {
    params: data,
  });
  return response.data;
};
const patientPrescriptionServices = {
  createPatientPrescription,
  getAllPrescription,
  deletePatientPrescription,
  getPescription,
  updatePatientPrescription,
  showPatientPrescription,
  getMedicationList
};

export default patientPrescriptionServices;
