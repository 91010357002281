import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import PaymentDetailsService from "./paymentDetails.service";

const initialState = {
  allPaymentDetails: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getPaymentDetails = createAsyncThunk(
  "paymentDetails/getAll",
  async (finalData, thunkAPI) => {
    try {
      return await PaymentDetailsService.getPaymentDetails(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const paymentDetailsSlice = createSlice({
  name: "paymentDetails",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getPaymentDetails.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getPaymentDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allPaymentDetails = action.payload.data;
      })
      .addCase(getPaymentDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = paymentDetailsSlice.actions;

export default paymentDetailsSlice.reducer;
