import React from "react";

const SignIn = React.lazy(() => import("./SignIn/index.jsx"));
const OtpCode = React.lazy(() => import("./SignIn/OtpCode/index.jsx"));
const ForgetPassword = React.lazy(() =>
  import("./SignIn/ForgetPassword/index.jsx")
);
const DoctorDashboard = React.lazy(() =>
  import("./Doctor/Dashboard/index.jsx")
);
const Appointment = React.lazy(() =>
  import("./Doctor/Appointment/fullCalendar.jsx")
);
const NoPageFound = React.lazy(() => import("./NoPageFound/index.jsx"));
const Patients = React.lazy(() =>
  import("./Doctor/PatientProfile/MyPatients.jsx")
);
const PatientsDetails = React.lazy(() =>
  import("./Doctor/PatientProfile/PatientDetails.jsx")
);
const TeleConsultation = React.lazy(() =>
  import("./Doctor/Appointment/TeleConsultation")
);
const Staff = React.lazy(() => import("./Doctor/Staff/Staff.jsx"));
const Chat = React.lazy(() => import("./Doctor/Chat/Chat.jsx"));
const Slots = React.lazy(() => import("./Doctor/Slots/Slots.jsx"));
const Profile = React.lazy(() => import("./Doctor/Profile/profile.jsx"));
const AppointmentNotesDetails = React.lazy(() =>
  import("./Doctor/PatientProfile/PatientAppointmentNotesDetails.jsx")
);
const AppointmentNotes = React.lazy(() =>
  import("./Doctor/Appointment/TeleConsultation/AppointmentNotes/index.jsx")
);
const StaffChat = React.lazy(() => import("./Doctor/Staff/StaffChat.jsx"));
const PatientPrescription = React.lazy(() =>
  import("./Patient/Prescription/Prescription.jsx")
);
const PatientDashboard = React.lazy(() =>
  import("./Patient/Dashboard/index.jsx")
);
const PatientPaymentDetail = React.lazy(() =>
  import("./Patient/Payments/PaymentDetails.jsx")
);
const Signup = React.lazy(() => import("./Signup/index.jsx"));
const PatientAppointmentDetails = React.lazy(() =>
  import("./Patient/Appointment/fullCalendar.jsx")
);
const PatientTeleConsultation = React.lazy(() =>
  import("./Patient/Appointment/TeleConsultation/index.jsx")
);
const PatientPROFILE = React.lazy(() =>
  import("./Doctor/PatientProfile/Profile.jsx")
);

const SuperAdmin = React.lazy(() =>
  import("./SuperAdmin/UserManagement/index.jsx")
);
const DoctorDetails = React.lazy(() =>
  import("./Patient/DoctorSlots/DoctorDetails.jsx")
);
const SuperadminPaymentDetails = React.lazy(() =>
  import("./SuperAdmin/PaymentDetails/index.jsx")
);
const SuperadminSettings = React.lazy(() =>
  import("./SuperAdmin/Settings/index.jsx")
);
const CompleteProfile = React.lazy(() =>
  import("../components/ProfileLayout/CompleteProfile.jsx")
);
const PersonalInformation = React.lazy(() =>
  import("./Patient/CompleteProfile/PersonalInformation.jsx")
);
const SuperAdminDashboard = React.lazy(() =>
  import("./SuperAdmin/Dashboard/index.jsx")
);
const AdminDashboard = React.lazy(() => import("./Admin/Dashboard/index.jsx"));
const Admin = React.lazy(() => import("./Admin/UserManagement/index.jsx"));
const AdminPaymentdetails = React.lazy(() =>
  import("./Admin/PaymentDetails/index.jsx")
);
const AdminSettings = React.lazy(() => import("./Admin/Settings/index.jsx"));
const MedicalHistory = React.lazy(() =>
  import("./Patient/CompleteProfile/MedicalHistory.jsx")
);
const Pharmacy = React.lazy(() =>
  import("./Patient/CompleteProfile/Pharmacy.jsx")
);
const ADMINDOCTORS = React.lazy(() =>
  import("./../components/Theme/DoctorsAppointment/index.jsx")
);
const SUPERADMINDOCTORS = React.lazy(() =>
  import("./../components/Theme/DoctorsAppointment/index.jsx")
);
const ADMINFEES = React.lazy(() =>
  import("./../components/Theme/FeesDataTable/index.jsx")
);
const SUPERADMINFEES = React.lazy(() =>
  import("./../components/Theme/FeesDataTable/index.jsx")
);
const ADMINQUESTIONNAIRE = React.lazy(() =>
  import("./SuperAdmin/Forms/FormsData/Questionnaires.jsx")
);
const SUPERADMINQUESTIONNAIRE = React.lazy(() =>
  import("./SuperAdmin/Forms/FormsData/Questionnaires.jsx")
);
const SUPERADMINFORMS = React.lazy(() => import("./SuperAdmin/Forms"));
const VIEWQUESTIONNAIRE = React.lazy(() =>
  import("./SuperAdmin/Forms/FormsData/ViewQuestionnaires.jsx")
);
const PatientPrescriptionForms = React.lazy(() =>
  import("./Patient/OnlinePrescriptionForms")
);
const DOCTORPRESCRIPTIONDASHBOARD = React.lazy(() =>
  import("./Doctor/OnlinePrescriptionForms")
);
const DOCTORPRESCRIPTIONFORM = React.lazy(() =>
  import("./Doctor/OnlinePrescriptionForms/PatientPrescription/index.jsx")
);
const TERMSANDCONDITIONS = React.lazy(() => import("./TermsAndConditions"));
const PRIVACYPOLICY = React.lazy(() => import("./PrivacyPolicy"));

const WEB_PAGES = {
  SIGNIN: SignIn,
  OTPCODE: OtpCode,
  FORGETPASSWORD: ForgetPassword,
  DOCTOR_DASHBOARD: DoctorDashboard,
  APPOINTMENT: Appointment,
  TELE_CONSULTATION: TeleConsultation,
  PATIENTS: Patients,
  PATIENTDETAILS: PatientsDetails,
  STAFF: Staff,
  CHAT: Chat,
  SLOTS: Slots,
  PROFILE: Profile,
  APPOINTMENTNOTESDETAILS: AppointmentNotesDetails,
  // PROFILEEDIT: ProfileEdit,
  APPOINTMENTNOTES: AppointmentNotes,
  STAFFCHAT: StaffChat,
  PATIENT_PRESCRIPTION: PatientPrescription,
  PATIENT_DASHBOARD: PatientDashboard,
  PATIENT_PAYMENTDETAILS: PatientPaymentDetail,
  SIGNUP: Signup,
  PATIENT_APPOINTMENTDETAILS: PatientAppointmentDetails,
  PATIENT_TELE_CONSULTATION: PatientTeleConsultation,
  PATIENT_PROFILE: PatientPROFILE,
  PATIENT_PRESCRIPTION_FORMS: PatientPrescriptionForms,
  SUPERADMIN_USERMANAGEMENT: SuperAdmin,
  SUPERADMIN_DASHBOARD: SuperAdminDashboard,
  DOCTOR_DETAILS: DoctorDetails,
  SUPERADMIN_PAYMENTDETAILS: SuperadminPaymentDetails,
  SUPERADMIN_SETTINGS: SuperadminSettings,
  COMPLETE_PROFILE: CompleteProfile,
  PERSONAL_INFORMATION: PersonalInformation,
  ADMIN_DASHBOARD: AdminDashboard,
  ADMIN_USERMANAGEMENT: Admin,
  ADMIN_PAYMENTDETAILS: AdminPaymentdetails,
  ADMIN_SETTINGS: AdminSettings,
  MEDICAL_HISTORY: MedicalHistory,
  PHARMACY: Pharmacy,
  NO_PAGE_FOUND: NoPageFound,
  ADMIN_DOCTORS: ADMINDOCTORS,
  SUPERADMIN_DOCTORS: SUPERADMINDOCTORS,
  SUPERADMIN_FEES: SUPERADMINFEES,
  ADMIN_FEES: ADMINFEES,
  ADMIN_FORMS: SUPERADMINFORMS,
  SUPERADMIN_FORMS: SUPERADMINFORMS,
  SUPERADMIN_QUESTIONNAIRE: SUPERADMINQUESTIONNAIRE,
  ADMIN_QUESTIONNAIRE: ADMINQUESTIONNAIRE,
  VIEW_QUESTIONNAIRE: VIEWQUESTIONNAIRE,
  DOCTOR_PRESCRIPTION_DASHBOARD: DOCTORPRESCRIPTIONDASHBOARD,
  DOCTOR_PRESCRIPTION_FORM: DOCTORPRESCRIPTIONFORM,
  TERMS_AND_CONDITIONS: TERMSANDCONDITIONS,
  PRIVACY_POLICY: PRIVACYPOLICY,
};
export default WEB_PAGES;
