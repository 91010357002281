/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */

import { Header } from "./Header";
import Sidebar from "./SideBar";

export function ParentComponentWithSideBar({ children, ...rest }) {
  return (
    <div className="main-wrapper">
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid pb-4 inner-page-wrapper">
          {children}
        </div>
      </div>
    </div>
  );
}
