import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { PATH } from "../utils/path";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FullScreenLoader from "./FullScreenLoader";

function PublicRoute({ element }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  
  const ROLE_PATHS = useMemo(
    () => ({
      2: PATH.DOCTOR_DASHBOARD,
      3: PATH.PATIENT_DASHBOARD,
      1: PATH.SUPERADMIN_DASHBOARD,
      4: PATH.ADMIN_DASHBOARD,
      5: PATH.STAFF_SCREEN_CHAT,
    }),
    []
  );

  const navigateBasedOnRole = () => {
    if (user?.roleId === 3) {
      if (user?.isFirstTimeLogin) {
        window.location.href = PATH.COMPLETE_PROFILE
        return;
      }
    }
    const path = ROLE_PATHS[user?.roleId];
    if (path) {
      window.location.href = path
      
    } else {
      console.log("Unknown role or data is null:");
    }
  };

  useEffect(() => {
    if (user) {
      navigateBasedOnRole();
    }
  }, [user, navigate]);

  if (user === undefined) return <FullScreenLoader />;

  return element;
}

PublicRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default PublicRoute;
