import api from "../../../utils/api";

const createAdminPresForm = async (saveData) => {
  const response = await api.post(`/form/save`, saveData);
  return response.data;
};

const updateAdminPresForm = async (data) => {
  const response = await api.post(`/form/QuestionUpdate`, data);
  return response.data;
};

const getAdminPresForm = async (data) => {
  const response = await api.get(`/form/get`, {
    params: data,
  });
  return response.data;
};

const getAllAdminPresForm = async (data) => {
  const response = await api.get(`/form/getAll`, data);
  return response.data;
};

const onlinePrescriptionFormServices = {
  createAdminPresForm,
  updateAdminPresForm,
  getAdminPresForm,
  getAllAdminPresForm,
};

export default onlinePrescriptionFormServices;
