import api from "../../../utils/api";

const getPaymentDetails = async (data) => {
  const response = await api.post(`/strip/getAllPayments`, data);
  return response.data;
};

const PaymentDetailsService = {
  getPaymentDetails,
};

export default PaymentDetailsService;
